import React from 'react'
import LandingPageSearch from '../../../templates/landing-page-search'

import content from '../../../data/pages/article/bye-bye-credit-card-debt/content.json'

const SDSArticleLP = () => (
  <LandingPageSearch content={content} />
)

export default SDSArticleLP
