import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Search from '../components/organisms/search'

const LandingPageSearch = ({
  content
}) => {
  return (
    <Layout
      type='lp-search'
      content={content.layout}
      classNames='t-landing-page t-landing-page--search'
      hideSecureForm
    >
      <SEO title={content.seo.title} />
      <Search 
        content={content.landingPage}
      />
    </Layout>
  )
}

export default LandingPageSearch
